@import "../../Assests/Styles/importFiles";

.feedback-wraper{
    .feedback-reply-form{
        .input-field {
            width: auto;
        }
    }
    .custom-row{
        display: flex;
    }
    .list-wrapper {
        margin-top: 10px;
        .list-item {
            border: 1px $light-blue solid;
            margin-bottom: 5px;
            border-radius: 5px;
            background:$white;
            &:hover{
                background: $light-blue;
            }
        }
    }
    .input-select-field {
        color: #0267AA;
        height:50px;
        border:2px solid $light-blue;
        border-radius: 5px;
        margin-bottom: 15px;
        margin-top: 10px;
        &.inValid{
            border:2px solid $red;
        }
        h6{
            color: $light-blue;
            width: fit-content;
            margin-top: -10px;
            margin-left: 10px;
            padding-left: 5px;
            padding-right: 5px;
            background: $blue-white;
            margin-block-end: 0.8em;
        }
        .css-1pahdxg-control{
            background: transparent;
            border: none;
        }
    .select-input {
        width: 100%;
        margin-bottom: 10px;
        &.inValid {
          border: 1px solid $red;
          border-radius: 3px;
        }
        .css-yk16xz-control
        {
            input{
                background: transparent;
                border: transparent;
            }
        }
        >div:last-of-type{
            height: auto;
            background:#E7F4FD;
            border: none;
            
        }
        .css-yk16xz-control{
            min-width: 56px;
        }
        div[class~="-control"]{
          background: $white $imp;
          box-shadow: none $imp;
          color: $purple2;
          font-size: 13px;
          border: transparent;
          border-radius: 3px;
          .Select-value {
            line-height: 27px;
          }
        }
        .Select-value-label {
          color: $purple2 $imp;
        }
        .Select-input {
          color: $purple2;
          font-size: 13px;
          height: auto;
      
          > input {
            min-width: 0;
            padding: 6px 0;
            line-height: 1;
          }
        }
        .Select-placeholder {
          line-height: 30px;
          color: $purple3;
          font-size: 13px;
        }
        .Select-menu-outer {
          border: none;
          &:before {
            content: "";
            box-shadow: rgb(136, 136, 136) 0px 0px 6px;
            position: absolute;
            top: 2px;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
          }
          .Select-menu {
            background: $white;
          }
        }
        .Select-noresults {
          padding: 6px 10px;
          font-size: 13px;
        }
        .Select-option {
          padding: 5px 6px;
          font-size: 13px;
        }
    }
    }
    .AddBtn{
        height: 50px;
        width: 50px;
        margin-top: 10px;
    }
    .input-field {
        height:50px;
        border:2px solid $light-blue;
        border-radius: 5px;
        margin-bottom: 15px;
        margin-top: 10px;
        &.inValid{
            border:2px solid $red;
        }
        h6{
            color: $light-blue;
            width: fit-content;
            margin-top: -10px;
            margin-left: 10px;
            padding-left: 5px;
            padding-right: 5px;
            background: $blue-white;
            margin-block-end: 0.8em;
        }
        input,textarea,select{
            background:transparent;
            margin-left: 10px;
            margin-right: 10px;
            width: -webkit-fill-available;
            border: none;
            outline: none;
        }
        div{
            display: flex;
            overflow: hidden;
            input{
                flex: 1;
                max-width: 100%;
                min-width: 80%;            
            }
            img{
                max-width: 15%;
                height: 20px;
                margin-top: 0px;
                margin-right: 5px;
                margin-left: -20px;
                &.visible{
                    visibility:visible;
                }
            }
        }
        &.no-label{
            img{
                margin-top: 10px;  
            }
        }
    }
    .input-field-textarea {
        height:auto;
        min-height: 80px;
        border:2px solid $light-blue;
        border-radius: 5px;
        margin-bottom: 15px;
        margin-top: 10px;
        &.inValid{
            border:2px solid $red;
        }
        h6{
            color: $light-blue;
            width: fit-content;
            margin-top: -10px;
            margin-left: 10px;
            padding-left: 5px;
            padding-right: 5px;
            background: $blue-white;
            margin-block-end: 0.8em;
        }
        input,textarea,select{
            background:transparent;
            margin-left: 10px;
            margin-right: 10px;
            width: -webkit-fill-available;
            border: none;
            outline: none;
        }
        div{
            display: flex;
            overflow: hidden;
            input{
                flex: 1;
                max-width: 100%;
                min-width: 80%;            
            }
            img{
                max-width: 15%;
                height: 20px;
                margin-top: 0px;
                margin-right: 5px;
                margin-left: -20px;
                &.visible{
                    visibility:visible;
                }
            }
        }
        &.no-label{
            img{
                margin-top: 10px;  
            }
        }
    }
    .fields-row{
        display: flex;
        >div{
            flex: 1;
            max-width: 44%;
            min-width: 44%;
            &:first-child{
                margin-right: 5%;
            }   
        }
        .input-field{
            div{
                max-width:100%;
            }
        }
    }
    .input-field-date {
        margin-top: 10px;
        height: 50px;
        border:2px solid $light-blue;
        border-radius: 5px;
        margin-bottom: 15px;
        width: 100%;
        &.inValid{
            border:2px solid $red;
        }
        h6{
            font-size: 0.9em;
            color: $light-blue;
            width: fit-content;
            margin-top: -10px;
            margin-left: 10px;
            padding-left: 5px;
            padding-right: 5px;
            background: $blue-white;
            margin-block-end: 0.8em;
        }
        input,textarea,select{
            background:transparent;
            margin-left: 10px;
            padding: 0 5px;
            width: -webkit-fill-available;
            border: none;
            outline: none;
        }
        .labelsTitle{
            width: 120px;
            overflow: hidden;
            margin-bottom: 10px;
            margin-left: 10px;
            padding-top: 12px;
        }
        .labelsListItem{
            display: inline-flex;
            width: 100%;
            overflow: hidden;
            min-width: 200px;
            margin-bottom: 10px;
            margin-left: 10px;
            input{
                margin-right: 35px;
                flex: 1;
                max-width: 100%;
                border-radius: 5px;          
            }
            img{
                max-width: 15%;
                height: 20px;
                margin-top: 0px;
                margin-right: 20px;
                &.visible{
                    visibility:visible;
                }
            }
        }
        &.no-label{
            img{
                margin-top: 10px;  
            }
        }
    }
    .add-btn{
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 70px;
      border-radius: 50%;
      background: $dark-blue;
      outline: none;
      padding: 0;
      height: 70px;
      margin-left: 10px;
      margin-bottom: 20px;
      margin-right: 20px;
      border: none;
      &:before {
          @extend %fa;
          color: $white;
          line-height: 2;
          content: $fa-plus;
          font-size: 20px;
          font-weight: lighter;
          @include border-radius(50%);
          box-sizing: border-box;
          display: inline-block;
      }
  }
}