@import "../../Assests/Styles/importFiles";
.list-wrapper {
  .list-ul {
    width: 100%;
    list-style: none;
    margin: 0;
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }
  .list-item {
    background: $white;
    font-size: 13px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    transition: 0.3s all;
    &:hover {
      font-weight: 500;
    }
    &.selected-item {
      background: $light-blue;
      font-weight: 500;
    }
    &.activeItem {
      color: $yellow;
    }
  }
}
