@import "../../Assests/Styles/importFiles.scss";
.landing-page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  .top-bar {
    height: 10px;
    img {
      vertical-align: top;
      width: 100%;
      height: 10px;
    }
  }
  .header {
    min-height: 30px;
    .left {
      @include breakpoint(screen991) {
        flex-flow: row;
        // max-width: 200px;
      }
    }
  }
  .header-mobile {
    display: none;
  }
  @include breakpoint(screen767) {
    .header-mobile {
      display: block;
    }
    .header {
      display: none;
    }
  }
  .body {
    background: $purple;
    display: flex;
    justify-content: center;
    padding-left: 25%;
    padding-right: 25%;
    background: $purple;
    flex: 1;
    min-height: 90vh;
    @include breakpoint(screen991) {
      flex-flow: row wrap;
      overflow: auto;
      min-height: 100%;
    }
    .cont {
      margin: 25px;
      align-self: center;
      h1 {
        text-align: center;
        color: $white;
        margin-left: 20px;
        margin-right: 20px;
      }
      .main {
        display: flex;
        flex-direction: column;
        border: 1px solid #8d77cc;
        border-radius: 10px;
        padding: 20px;
        p {
          margin-block-start: 0;
          margin-block-end: 0px;
          text-align: center;
          color: $white;
        }
        button {
          align-self: center;
          background: #04caa7;
          color: $white;
          margin-top: 10px;
          cursor: pointer;
          border: none;
          border-radius: 4px;
          padding: 6px 10px;
        }
      }
    }
  }
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 600;
}
