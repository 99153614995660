@import "../../Assests/Styles/importFiles";
.confirm-dialog {
  top: 0px;
  left: 0;
  right: 0;
  margin: auto;
  position: fixed;
  width: 90%;
  text-align: center;
  padding: 5px;
  z-index: 1001;
  font-size: 13px;
  border-radius: 0px 0px 5px 5px;
  border-bottom: 2px solid darken($blue, 20);
  color: $white;
  box-shadow: rgba($black, 0.1) 0px 2px 4px;
  background:  $blue;
  display: flex;
  flex-flow: row;
  align-items: center;
  @include breakpoint(screen640) {
    flex-flow: row wrap;
  }
  .confirm-message {
    flex: 1;
  }
  .confirm-action-buttons {
    @include breakpoint(screen640) {
      width: 100%;
      margin-top: 10px;
    }
  }
  .dialog-btn {
    background: $green;
    border: none;
    min-width: 70px;
    padding: 6px 7px;
    border-radius: 3px;
    margin-left: 10px;
  }
  .info-dialog {
    background: $blue;
    border-bottom: 2px solid darken($blue, 20);
  }
}
