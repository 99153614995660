@import "../../Assests/Styles/importFiles";

.login-form {
  width: 500px;
  position: fixed;
  z-index: 1100;
  top: 30%;
  left: 35%;
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  @include breakpoint(screen991) {
    top: 125px;
    left: 1%;
    right: 1%;
    width: auto;
  }
  input,
  .login-btn,
  .login-forgot {
    height: 35px;
    font-family: rubik, Arial, sans-sarif;
    text-align: center;
    margin-bottom: 15px;
    width: 100%;
    background: #f5f2ff;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    &.inValid {
      border: 1px solid $red;
    }
    outline: none;
  }
  .login-btn {
    background: #04caa7;
    color: $white;
  }
  .login-forgot {
    background: $white;
    color: $black;
    border: none;
  }
  .login-forgot:hover {
    background: #04caa7;
    color: $white;
  }
}
.Forgot {
  font-family: $RubikRegular; // background: $green;
  display: block; // color: #fff;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 7px;
  &:hover {
    color: #fff;
    background: $green;
  }
}
