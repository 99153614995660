@import "../../Assests/Styles/importFiles";
.lookup-wrapper {
  .lookupForm {
    display: flex;
    flex-direction: column;
    padding: 20px;
    .input-field {
      height: 40px;
      border: 2px solid $light-blue;
      border-radius: 5px;
      margin-bottom: 15px;
      &.inValid {
        border: 2px solid $red;
      }
      h6 {
        color: $light-blue;
        width: fit-content;
        margin-top: -10px;
        margin-left: 10px;
        padding-left: 5px;
        padding-right: 5px;
        background: $blue-white;
        margin-block-end: 0.8em;
      }
      input,
      textarea,
      select {
        background: transparent;
        margin-left: 10px;
        margin-right: 10px;
        width: -webkit-fill-available;
        border: none;
        outline: none;
      }
      div {
        display: flex;
        overflow: hidden;
        input {
          flex: 1;
          max-width: 100%;
          min-width: 80%;
        }
        img {
          visibility: hidden;
          max-width: 15%;
          height: 20px;
          margin-top: 0px;
          margin-right: 5px;
          margin-left: -20px;
          &.visible {
            visibility: visible;
          }
        }
      }
      &.no-label {
        img {
          margin-top: 10px;
        }
      }
    }
    .no-label {
      input {
        padding-top: 10px;
      }
    }

    .fields-row {
      display: flex;
      > div {
        flex: 1;
        max-width: 44%;
        min-width: 44%;
        &:first-child {
          margin-right: 10%;
        }
      }
      .input-field {
        div {
          max-width: 100%;
        }
      }
    }
    .input-field.text-area {
      height: 60px;
    }
  }
  .list-item {
    padding: 10px;
    display: flex;
    border-bottom: 1px solid $background-color;
    div {
      flex: 1;
      h4,
      p {
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }
    &:hover {
      background: $light-blue;
    }
    &.active {
      background: $light-blue;
    }
  }
  .column-body {
    .list-wrapper {
      overflow: auto;
    }
    position: relative;
    .add-btn {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 70px;
      border-radius: 50%;
      background: $dark-blue;
      outline: none;
      padding: 0;
      height: 70px;
      margin-left: 10px;
      margin-bottom: 20px;
      margin-right: 20px;
      border: none;
      &:before {
        @extend %fa;
        color: $white;
        line-height: 2;
        content: $fa-plus;
        font-size: 20px;
        font-weight: lighter;
        @include border-radius(50%);
        box-sizing: border-box;
        display: inline-block;
      }
    }
  }
  .subscription-configurations {
    display: flex;
    flex-direction: column;
    .btn-login {
      margin-right: 0px;
    }
  }
  .excution-list {
    padding-inline-start: 0;
    li {
      display: flex;
      span {
        flex: 2;
        align-self: center;
      }
      img {
        max-height: 20px;
        max-width: 20px;
      }
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  .excuetion-options {
    margin: 10px 20px;
    justify-content: space-between;
    display: flex;
    input {
      align-self: center;
    }
  }
  .execute-loader {
    border: 1px solid;
    border-radius: 20px;
    min-height: 20px;
    transition: all 0.3s;
    div {
      background-color: $blue;
      border-radius: 20px;
      color: $white;
      text-align: end;
      padding-right: 5px;
      transition: all 0.1s;
      height: 20px;
      &.width-10 {
        width: 0%;
      }
      &.width-20 {
        width: 20%;
      }
      &.width-30 {
        width: 30%;
      }
      &.width-40 {
        width: 40%;
      }
      &.width-50 {
        width: 50%;
      }
      &.width-60 {
        width: 60%;
      }
      &.width-70 {
        width: 70%;
      }
      &.width-80 {
        width: 80%;
      }
      &.width-90 {
        width: 90%;
      }
      &.width-100 {
        width: 100%;
      }
    }
  }
  .execution-summarry {
    margin-top: 20px;
    border: 1px solid;
    padding: 5px;
    h3 {
      text-align: center;
    }
    hr {
      height: 1px;
    }
  }
  .execution-result {
    .btn-login {
      margin-top: 20px;
      min-width: 100%;
    }
  }
  .progress-bar {
    display: flex;
    margin-top: 10px;
    progress {
      width: 90%;
      height: 20px;
      background-image: -webkit-linear-gradient(
          135deg,
          transparent,
          transparent 33%,
          rgba(0, 0, 0, 0.1) 33%,
          rgba(0, 0, 0, 0.1) 66%,
          transparent 66%
        ),
        -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
        -webkit-linear-gradient(left, #09c, #ff0);
    }
    label {
      background: #e7f4fd;
    }
  }
  .columnSearch {
    padding-top: 0px;
    padding-left: 0px;
  }
  .list-wrapper {
    margin-top: 10px;
    .list-item {
      border: 1px $light-blue solid;
      margin-bottom: 5px;
      border-radius: 5px;
      background: $white;
      &:hover {
        background: $light-blue;
      }
    }
  }
  .list-wrapper .list-ul {
    padding: 0px;
  }
  .lookupForm {
    .AddBtn {
      width: 44px;
      border-radius: 3px;
      background: #04caa7;
      outline: none;
      padding: 0;
      height: 44px;
      margin-left: 10px;
      border: none;
    }
    .AddBtn:before {
      font-size: 25px;
      content: "";
      color: $white;
      vertical-align: middle;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin: -1px 0 0 1px;
      box-sizing: border-box;
    }
  }
  .round-search {
    border: 1px solid #cccccc;
  }
  .lookupForm .searchfield {
    display: block;
    flex: 1;
  }
  .lookupForm .CustomSearch {
    padding-left: 0px;
    padding-top: 6px;
  }
}

.input-field-custom {
  height: 40px;
  border: 2px solid $light-blue;
  border-radius: 5px;
  margin-bottom: 15px;
  &.inValid {
    border: 2px solid $red;
  }
  h6 {
    color: $light-blue;
    width: fit-content;
    margin-top: -10px;
    margin-left: 10px;
    padding-left: 5px;
    padding-right: 5px;
    background: $blue-white;
    margin-block-end: 0.8em;
  }
  input,
  textarea,
  select {
    background: transparent;
    margin-left: 10px;
    margin-right: 10px;
    width: -webkit-fill-available;
    border: none;
    outline: none;
  }
  div {
    display: flex;
    overflow: hidden;
    input {
      flex: 1;
      max-width: 100%;
      min-width: 80%;
    }
    img {
      max-width: 15%;
      height: 20px;
      margin-top: 0px;
      margin-right: 5px;
      margin-left: -20px;
      &.visible {
        visibility: visible;
      }
    }
  }
  &.no-label {
    img {
      margin-top: 10px;
    }
  }
}
.divConfigVersion {
  display: flex;
  flex-direction: column;
  padding: 20px;
  .excel-input {
    left: 30px;
    cursor: pointer;
  }
  input[type="checkbox"] {
    margin-left: 5px;
    /* Double-sized Checkboxes */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(1);
    padding: 10px;
    margin-right: 5px;
    margin-top: -2px;
    background: #fff;
    color: black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #77b5df;
    position: relative;
    /* left: -5px; */
    /* top: -5px; */
    border-radius: 5px;
    height: 16px;
    width: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    outline: none;
    cursor: pointer;
    &:checked {
      background: #0267aa;
    }
    outline: none;
  }
  a {
    display: inline-flex;
  }
  img {
    max-width: 30px;
    height: 30px;
    margin-top: 3px;
    margin-left: 10px;
    &.visible {
      visibility: visible;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    margin: 0px 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #77b5df;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #0267aa;
  }

  .list-checkbox {
    align-self: center;
    margin-right: 5px;

    input[type="radio"] {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid #77b5df;
      position: relative;
      /* left: -5px; */
      /* top: -5px; */
      border-radius: 50%;
      &:checked {
        background: #0267aa;
      }
      outline: none;
      /* Double-sized radio buttn */
      -ms-transform: scale(2); /* IE */
      -moz-transform: scale(2); /* FF */
      -webkit-transform: scale(2); /* Safari and Chrome */
      -o-transform: scale(2); /* Opera */
      transform: scale(1.1);
      padding: 10px;
      margin-right: 5px;
    }

    .no-item-label {
      text-align: center;
      font-size: 12px;
      color: #77b5df;
    }
  }
}
.btn-Add {
  font-family: rubik, Arial, sans-sarif;
  background: #04caa7;
  color: #fff;
  font-size: 16px;
  padding: 8px 18px;
  display: inline-block;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  outline: none;
  margin-right: 10px;
  margin-left: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-AddForConfigEditor {
  font-family: rubik, Arial, sans-sarif;
  background: #04caa7;
  color: #fff;
  font-size: 16px;
  padding: 8px 18px;
  display: inline-block;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  outline: none;
  margin-right: 10px;
  margin-left: 20px;
  margin-top: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
