@import "../../Assests/Styles/importFiles";

.newMessage {
  position: absolute;
  left: 30px;
  top: 0;
  font-size: 10px;
  padding: 0;
  background: $yellow;
  text-align: center;
  height: 15px;
  letter-spacing: -0.5px;
  min-width: 15px;
  line-height: 16px;
  color: $white;
  @include border-radius(10px);
}
