@import "../../../Assests/Styles/importFiles";
.feedback-detail-component {
  display: flex;
  width: 100%;
  .FeedbackWrapper {
    width: 100%;
  }
  .feedbackItem {
    display: table;
    width: 100%;
    padding: 10px 10px 0;
  }
  .titleLabel {
    font-size: 15px;
    font-family: $RubikRegular;
    color: $purple4;
    padding: 5px 0 10px;
    text-align: center;
    display: block;
    border-bottom: 1px solid $offwhite;
  }
  .feedbackWrap {
    .labelName {
      min-width: 120px;
    }
  }

  .labelName {
    color: $purple4;
    min-width: 90px;
    display: table-cell;
    font-family: $RubikRegular;
  }

  .FBDetailWrap {
    margin: 0px 10px 10px;
    color: $purple4;
    line-height: 1.2;
  }

  .answerLabel {
    color: $purple4;
    width: 100%; // background: $offwhite;
    padding: 6px 10px;
    display: table-cell;
    border-radius: 3px;
  }
  .FeedbackImg{
    width: 100%;
  }
  .btn-login{
    font-family: rubik,Arial,sans-sarif;
    background: #04caa7;
    color: #fff;
    font-size: 16px;
    padding: 6px 10px;
    display: inline-block;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
    -webkit-transition: all .3s;
    transition: all .3s;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 100%;
  }
  .feedback-reply-form{
    width: inherit;
  }
}
