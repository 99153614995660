@import "../../Assests/Styles/importFiles";
html, body,#root {
    height: 100%;
    margin: 0; padding: 0;  /* to avoid scrollbars */
}
.page-wrapper{
    .notification{
        top: 0px;
        left: 0;
        right: 0;
        margin: auto;
        position: fixed;
        width: 90%;
        text-align: center;
        padding: 8px 10px 9px;
        z-index: 2000;
        font-size: 13px;
        border-radius: 0px 0px 5px 5px;
        border-bottom: 2px solid darken($lightGreen, 10);
        color: $white;
        box-shadow: rgba($black, 0.1) 0px 2px 4px;
        background: url("../../Assests/Images/dialog-bg.png") repeat-x 0 0 $lightGreen;
        cursor: pointer;
        &.success {
          background-color: $lightGreen;
        }
        &.error {
          background-color: $red;
          border-bottom: 2px solid darken($red, 20);
        }
        &.info {
          background-color: $blue;
          border-bottom: 2px solid darken($blue, 20);
        }
    } 
    display: flex;
    flex-direction: column;
    min-height: 100%;;
    max-height: 100%;
    max-height: 100vh;
    overflow: hidden;
    .top-bar{
        z-index: 66;
        height: 10px;
        img{
            vertical-align: top;
            width: 100%;
            height: 10px;
        }
    }
    .main-header-mobile{
        display: none;
    }
    @include breakpoint(screen991){
        .main-header-mobile{
            display: block;
        }
        .header-mobile{
            display: block;
        }
        .header{
            display: none;
        }
    }
    .header{
        z-index: 55;
        .left{
            display: flex;
            span{
                align-self: center;
            }
        }
        @include breakpoint(screen1240) {
            margin-left: 48px;
          }
        @include breakpoint(screen767){
            height: 100px;
            margin-left: 48px;
        }

        .left{
            @include breakpoint(screen991){
                flex-flow: row;
                max-width: 100%;
                align-self: center;
            }       
        }
        .middle{
            @include breakpoint(screen991){
                align-self: center;
            }
        }
    }

    .right{
        max-width: 350px;
        display: flex;
        @include breakpoint(screen991){
            flex-flow: row-reverse;
            max-width: 100%;
            align-self: center;
        }  
        a{
            text-decoration: none;
            color: #FFF;
            margin-left: 10px;
            margin-top: 5px;
        }
        img{
            max-width: 100px;
            max-height: 40px;
            vertical-align: center;
        }
    }
    .page-body{
        flex: 1;
        background: $blue;
        color: $white;
        display: flex;
        max-height: 100vh;
        overflow: hidden;
        .page-sidebar{
            width: 200px;
            padding: 20px;
            background: #0267AA;
            -webkit-transition: all .3s;
            transition: all .3s;
            @include breakpoint(screen991){
                width: 45px;
                overflow: hidden;
            }
            &.collapsed{
                width: 45px;
                overflow: hidden;
            }
            >img{
                float: right;
                margin-right: 8px;
            }
            .menu-items{
                padding-top: 10px;            
                .menu-item{
                    display: flex;
                    margin-top: 20px;
                    img{
                        width: 50px;
                    }
                    span{
                        align-self: center;
                        padding-left: 20px;
                    }
                }
            }
        }
        .page-view{
            background: $background-color;
            display: flex;
            flex: 1 1;
            flex-flow: row;
            min-height: 100%;
            margin: 0;
            padding: 10px;
            overflow: hidden;
            .columns-wrapper{
                display: flex;
                min-width: 100%;
                margin-bottom: 10px;
                @include breakpoint(screen991){
                    flex-flow: column;
                    overflow-y: auto;  
                }

            }
            @include breakpoint(screen1240) {
                margin-left: 48px;
              }
        }
    }
}