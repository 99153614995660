@import "../../Assests/Styles/importFiles";

.features-wrapper {
  .list-wrapper {
    .list-item {
      border: 1px $light-blue solid;
      margin-bottom: 5px;
      border-radius: 5px;
      background: $white;
      &:hover {
        background: $light-blue;
      }
    }
  }
  .input-select-field {
    color: #0267aa;
    height: auto;
    border: 2px solid $light-blue;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-top: 8px;
    h6 {
      color: $light-blue;
      width: fit-content;
      margin-top: -10px;
      margin-left: 10px;
      padding-left: 5px;
      padding-right: 5px;
      background: $blue-white;
      margin-block-end: 0.8em;
    }
    .css-1pahdxg-control {
      background: transparent;
      border: none;
    }
    .select-input {
      width: 100%;
      &.inValid {
        border: 1px solid $red;
        border-radius: 3px;
      }
      .css-yk16xz-control {
        input {
          background: transparent;
          border: transparent;
        }
      }
      > div:last-of-type {
        height: auto;
        background: #e7f4fd;
        border: none;
      }
      .css-yk16xz-control {
        min-width: 56px;
      }
      div[class~="-control"] {
        background: $white $imp;
        box-shadow: none $imp;
        color: $purple2;
        font-size: 13px;
        border: transparent;
        border-radius: 3px;
        .Select-value {
          line-height: 27px;
        }
      }
      .Select-input {
        color: $blue;
        font-size: 13px;
        height: auto;

        > input {
          min-width: 0;
          padding: 6px 0;
          line-height: 1;
        }
      }
      .Select-placeholder {
        line-height: 30px;
        color: $purple3;
        font-size: 13px;
      }
      .Select-menu-outer {
        border: none;
        &:before {
          content: "";
          box-shadow: rgb(136, 136, 136) 0px 0px 6px;
          position: absolute;
          top: 2px;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;
        }
        .Select-menu {
          background: $white;
        }
      }
      .Select-noresults {
        padding: 6px 10px;
        font-size: 13px;
      }
      .Select-option {
        padding: 5px 6px;
        font-size: 13px;
      }
    }
  }
  .text-center {
    text-align: center;
  }
  .sub-list {
    margin-left: 20px;
  }
  input[type="checkbox"] {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: -2px;
    padding: 10px;
    margin-right: 5px;
    background: #fff;
    background: #e7f4fd;
    color: black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #77b5df;
    position: relative;
    /* left: -5px; */
    /* top: -5px; */
    border-radius: 5px;
    height: 16px;
    width: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    outline: none;
    cursor: pointer;
    margin-top: 5px;
    &:checked {
      background: #0267aa;
    }
    outline: none;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    margin: 0px 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #77b5df;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #0267aa;
  }
  .list-checkbox {
    align-self: center;
    margin-right: 5px;
    margin-left: 5px;
    input[type="checkbox"] {
      /* Double-sized Checkboxes */
      -ms-transform: scale(2); /* IE */
      -moz-transform: scale(2); /* FF */
      -webkit-transform: scale(2); /* Safari and Chrome */
      -o-transform: scale(2); /* Opera */
      transform: scale(1);
      padding: 10px;
      margin-right: 5px;
    }
  }
  .list_item_title {
    margin-top: 10px;
  }
  .no-item-label {
    text-align: center;
    font-size: 12px;
    color: #77b5df;
  }
  .list-item {
    padding: 10px;
    display: flex;
    border-bottom: 1px solid $background-color;
    .list_item_title {
      margin-top: 8px;
    }
    div {
      flex: 1;
      margin-top: 5px;
      h4,
      p {
        margin-block-start: 0;
        margin-block-end: 0;
        font-size: 15px;
        font-weight: 400;
      }
    }
    &:hover {
      background: $light-blue;
    }
    &.active {
      background: $light-blue;
    }
    &.selected {
      background: $light-blue;
    }
  }
}
.arrow-down-icon {
  background: $fi-arrow-down;
}
.arrow-icon {
  height: 15px;
  width: 15px;
  background: transparent;
  border-radius: 3px;
  border: none;
  font-size: 0;
  margin-left: -10px;
  align-self: center;
  &:before {
    @extend %Im;
    font-size: 15px;
    content: $fi-arrow-down;
    color: $purple;
    vertical-align: middle;
    margin: -1px 0px 0 1px;
    box-sizing: border-box;
    transform: rotate(270deg);
  }
  &.uncollapsed {
    transform: rotate(90deg);
  }
  &.hidden {
    visibility: hidden;
  }
  &.visible {
    visibility: visible;
  }
}
.feature-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  .input-field {
    height: 40px;
    border: 2px solid $light-blue;
    border-radius: 5px;
    margin-bottom: 15px;
    &.inValid {
      border: 2px solid $red;
    }
    h6 {
      color: $light-blue;
      width: fit-content;
      margin-top: -10px;
      margin-left: 10px;
      padding-left: 5px;
      padding-right: 5px;
      background: $blue-white;
      margin-block-end: 0.8em;
    }
    input,
    textarea,
    select {
      background: transparent;
      margin-left: 10px;
      margin-right: 10px;
      width: -webkit-fill-available;
      border: none;
      outline: none;
    }
    div {
      display: flex;
      overflow: hidden;
      input {
        flex: 1;
        max-width: 100%;
        min-width: 80%;
      }
      img {
        visibility: hidden;
        max-width: 15%;
        height: 20px;
        margin-top: 0px;
        margin-right: 5px;
        margin-left: -20px;
        &.visible {
          visibility: visible;
        }
      }
    }
    &.no-label {
      img {
        margin-top: 10px;
      }
    }
  }
  .no-label {
    input {
      padding-top: 10px;
    }
  }
  .fields-row {
    display: flex;
    > div {
      flex: 1;
      max-width: 44%;
      min-width: 44%;
      &:first-child {
        margin-right: 10%;
      }
    }
    .input-field {
      div {
        max-width: 100%;
      }
    }
  }
  .input-field.text-area {
    height: 60px;
  }
  .column-body {
    .list-wrapper {
      overflow: auto;
    }
    position: relative;
    .add-btn {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 70px;
      border-radius: 50%;
      background: $dark-blue;
      outline: none;
      padding: 0;
      height: 70px;
      margin-left: 10px;
      margin-bottom: 20px;
      margin-right: 20px;
      border: none;
      &:before {
        @extend %fa;
        color: $white;
        line-height: 2;
        content: $fa-plus;
        font-size: 20px;
        font-weight: lighter;
        @include border-radius(50%);
        box-sizing: border-box;
        display: inline-block;
      }
    }
  }
  #root
    > div
    > div.page-body
    > div.page-view
    > div
    > div
    > div.column-body
    > div:nth-child(1)
    > div
    > div,
  #root
    > div
    > div.page-body
    > div.page-view
    > div
    > div
    > div.column-body
    > div:nth-child(2)
    > div
    > div,
  #root
    > div
    > div.page-body
    > div.page-view
    > div
    > div
    > div.column-body
    > div:nth-child(3)
    > div
    > div {
    border-color: hsl(0, 0%, 80%);
    border-radius: 0px !important;
    border-style: none !important;
    border-width: 0px !important;
    box-shadow: 0 0 0 0 #e7f4fd !important;
  }
}
