@import "../../Assests/Styles/importFiles";

$animation-duration: 2s;
$avatar-offset: 52 + 16;

// this unfortunately uses set px widths for the background-gradient, I never got around to changing it to work with all widths :(
@mixin background-gradient {
  background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    linear-gradient($shine-background-color 20px, rgba(0, 0, 0, 0) 0%),
    linear-gradient($shine-background-color 20px, rgba(0, 0, 0, 0) 0%),
    linear-gradient($shine-background-color 20px, rgba(0, 0, 0, 0) 0%),
    linear-gradient($shine-background-color 20px, rgba(0, 0, 0, 0) 0%);
  background-repeat: no-repeat;
  background-size: 50px 100%, 90% 200px, 90% 200px, 90% 200px, 90% 200px;
  background-position: 0 0, 50% 50px, 50% 100px, 50% 150px, 50% 200px;
  animation: listShine 1s infinite;
  background-color: $shine-background-color;
}

.navigation-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.post {
  width: 220px;
  .avatar {
    float: left;
    width: 30px;
    height: 30px;
    background-color: $gray3;
    border-radius: 50%;
    margin: 8px;
    @include background-gradient;
    @include breakpoint(screen767) {
      margin-left: 94px;
    }
    @include breakpoint(screen1240) {
      margin-left: 94px;
    }
  }
  .line {
    float: left;
    width: 140px;
    height: 15px;
    margin-top: 16px;
    @include background-gradient;
    @include breakpoint(screen767) {
      display: none;
    }

    @include breakpoint(screen1240) {
      display: none;
    }
  }
  .avatar + .line {
    margin-top: 15px;
  }
  .line ~ .line {
    background-color: $grayScale;
  }
}
