@import "../../Assests/Styles/importFiles";

.admin-management-role-wraper {
  .Select-control{
    height: 30px !important;
  }
  .btn-search{
    font-family: rubik,Arial,sans-sarif;
    background: #04caa7;
    color: #FFF;
    font-size: 16px;
    padding: 6px 10px;
    display: inline-block;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
    transition: all .3s;
    height: 45px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .AddBtnRole {
    height: 50px;
    width: 50px;
    margin-top: 10px;
    background: $green;
    border-radius: 3px;
    border: none;
    margin-left: 10px;
    font-size: 0;
    
    &:before {
      @extend %Im;
      font-size: 20px;
      content: $fi-close;
      color: $primaryColor1;
      vertical-align: middle;
      transform: rotate(45deg);
      margin: -1px 0px 0 1px;
    }
    
  }
  .admin-save-form {
    .input-field {
      width: auto;
    }
  }
  .list-wrapper {
    margin-top: 10px;
    .list-item {
      border: 1px $light-blue solid;
      margin-bottom: 5px;
      border-radius: 5px;
      background: $white;
      &:hover {
        background: $light-blue;
      }
    }
  }
  input[type="checkbox"] {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: -2px;
    padding: 10px;
    margin-right: 5px;
    background: #fff;
    background: #e7f4fd;
    color: black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #77b5df;
    position: relative;
    /* left: -5px; */
    /* top: -5px; */
    border-radius: 5px;
    height: 16px;
    width: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    outline: none;
    cursor: pointer;
    margin-top: 5px;
    &:checked {
      background: #0267aa;
    }
    outline: none;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    margin: 0px 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #77b5df;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #0267aa;
  }
  .list_item_title {
    margin-top: 10px;
  }
  .list-checkbox {
    align-self: center;
    margin-right: 5px;
    margin-left: 5px;
    input[type="checkbox"] {
      /* Double-sized Checkboxes */
      -ms-transform: scale(2); /* IE */
      -moz-transform: scale(2); /* FF */
      -webkit-transform: scale(2); /* Safari and Chrome */
      -o-transform: scale(2); /* Opera */
      transform: scale(1);
      padding: 10px;
      margin-right: 5px;
    }
  }
  .input-select-field {
    color: #0267aa;
    height: 50px;
    border: 2px solid $light-blue;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-top: 10px;
    &.inValid {
      border: 2px solid $red;
    }
    h6 {
      color: $light-blue;
      width: fit-content;
      margin-top: -10px;
      margin-left: 10px;
      padding-left: 5px;
      padding-right: 5px;
      background: $blue-white;
      margin-block-end: 0.8em;
    }
    .css-1pahdxg-control {
      background: transparent;
      border: none;
    }
    .select-input {
      width: 100%;
      margin-bottom: 10px;
      &.inValid {
        border: 1px solid $red;
        border-radius: 3px;
      }
      .css-yk16xz-control {
        input {
          background: transparent;
          border: transparent;
        }
      }
      > div:last-of-type {
        height: auto;
        background: #e7f4fd;
        border: none;
      }
      .css-yk16xz-control {
        min-width: 56px;
      }
      div[class~="-control"] {
        background: $white $imp;
        box-shadow: none $imp;
        color: $purple2;
        font-size: 13px;
        border: transparent;
        border-radius: 3px;
        .Select-value {
          line-height: 27px;
        }
      }
      .Select-input {
        color: $purple2;
        font-size: 13px;
        height: auto;

        > input {
          min-width: 0;
          padding: 6px 0;
          line-height: 1;
        }
      }
      .Select-placeholder {
        line-height: 30px;
        color: $purple3;
        font-size: 13px;
      }
      .Select-menu-outer {
        border: none;
        &:before {
          content: "";
          box-shadow: rgb(136, 136, 136) 0px 0px 6px;
          position: absolute;
          top: 2px;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;
        }
        .Select-menu {
          background: $white;
        }
      }
      .Select-noresults {
        padding: 6px 10px;
        font-size: 13px;
      }
      .Select-option {
        padding: 5px 6px;
        font-size: 13px;
      }
    }
  }
  
  .adminRoleInput-field {
    display: flex;
    flex-flow: row;
  }
  .input-field {
    margin-top: 10px;
    height: 50px;
    border: 2px solid $light-blue;
    border-radius: 5px;
    margin-bottom: 15px;
    width: 100%;
    &.inValid {
      border: 2px solid $red;
    }
    h6 {
      font-size: 0.9em;
      color: $light-blue;
      width: fit-content;
      margin-top: -10px;
      margin-left: 10px;
      padding-left: 5px;
      padding-right: 5px;
      background: $blue-white;
      margin-block-end: 0.8em;
    }
    input,
    textarea,
    select {
      background: transparent;
      margin-left: 10px;
      width: -webkit-fill-available;
      border: none;
      outline: none;
    }
    .labelsTitle {
      width: 120px;
      overflow: hidden;
      margin-bottom: 10px;
      margin-left: 10px;
      padding-top: 12px;
    }
    .labelsListItem {
      display: inline-flex;
      width: 100%;
      overflow: hidden;
      min-width: 200px;
      margin-bottom: 10px;
      margin-left: 10px;
      input {
        margin-right: 35px;
        flex: 1;
        max-width: 100%;
        border-radius: 5px;
      }
      img {
        max-width: 15%;
        height: 20px;
        margin-top: 0px;
        margin-right: 20px;
        &.visible {
          visibility: visible;
        }
      }
    }
    &.no-label {
      img {
        margin-top: 10px;
      }
    }
  }
  .fields-row {
    display: flex;
    > div {
      flex: 1;
      max-width: 44%;
      margin-right: 2%;
    }
    .input-field {
      div {
        max-width: 100%;
      }
    }
  }
  .input-field-date {
    margin-top: 10px;
    height: 50px;
    border: 2px solid $light-blue;
    border-radius: 5px;
    margin-bottom: 15px;
    width: 100%;
    &.inValid {
      border: 2px solid $red;
    }
    h6 {
      font-size: 0.9em;
      color: $light-blue;
      width: fit-content;
      margin-top: -10px;
      margin-left: 10px;
      padding-left: 5px;
      padding-right: 5px;
      background: $blue-white;
      margin-block-end: 0.8em;
    }
    input,
    textarea,
    select {
      background: transparent;
      margin-left: 10px;
      padding: 0 5px;
      width: -webkit-fill-available;
      border: none;
      outline: none;
    }
    .labelsTitle {
      width: 120px;
      overflow: hidden;
      margin-bottom: 10px;
      margin-left: 10px;
      padding-top: 12px;
    }
    .labelsListItem {
      display: inline-flex;
      width: 100%;
      overflow: hidden;
      min-width: 200px;
      margin-bottom: 10px;
      margin-left: 10px;
      input {
        margin-right: 35px;
        flex: 1;
        max-width: 100%;
        border-radius: 5px;
      }
      img {
        max-width: 15%;
        height: 20px;
        margin-top: 0px;
        margin-right: 20px;
        &.visible {
          visibility: visible;
        }
      }
    }
    &.no-label {
      img {
        margin-top: 10px;
      }
    }
  }

  .column-body {
    .list-wrapper {
      overflow: auto;
    }
    position: relative;
    .add-btn {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 70px;
      border-radius: 50%;
      background: $dark-blue;
      outline: none;
      padding: 0;
      height: 70px;
      margin-left: 10px;
      margin-bottom: 20px;
      margin-right: 20px;
      border: none;
      &:before {
        @extend %fa;
        color: $white;
        line-height: 2;
        content: $fa-plus;
        font-size: 20px;
        font-weight: lighter;
        @include border-radius(50%);
        box-sizing: border-box;
        display: inline-block;
      }
    }
  }
  .subscription-configurations {
    display: flex;
    flex-direction: column;
    .btn-login {
      margin-right: 0px;
    }
  }
  .excution-list {
    padding-inline-start: 0;
    li {
      display: flex;
      span {
        flex: 2;
        align-self: center;
      }
      img {
        max-height: 20px;
        max-width: 20px;
      }
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  .excuetion-options {
    margin: 10px 20px;
    justify-content: space-between;
    display: flex;
    input {
      align-self: center;
    }
  }
  .execute-loader {
    border: 1px solid;
    border-radius: 20px;
    min-height: 20px;
    transition: all 0.3s;
    div {
      background-color: $blue;
      border-radius: 20px;
      color: $white;
      text-align: end;
      padding-right: 5px;
      transition: all 0.1s;
      height: 20px;
      &.width-10 {
        width: 0%;
      }
      &.width-20 {
        width: 20%;
      }
      &.width-30 {
        width: 30%;
      }
      &.width-40 {
        width: 40%;
      }
      &.width-50 {
        width: 50%;
      }
      &.width-60 {
        width: 60%;
      }
      &.width-70 {
        width: 70%;
      }
      &.width-80 {
        width: 80%;
      }
      &.width-90 {
        width: 90%;
      }
      &.width-100 {
        width: 100%;
      }
    }
  }
  .execution-summarry {
    margin-top: 20px;
    border: 1px solid;
    padding: 5px;
    h3 {
      text-align: center;
    }
    hr {
      height: 1px;
    }
  }
  .execution-result {
    .btn-login {
      margin-top: 20px;
      min-width: 100%;
    }
  }
  .progress-bar {
    display: flex;
    margin-top: 10px;
    progress {
      width: 90%;
      height: 20px;
      background-image: -webkit-linear-gradient(
          135deg,
          transparent,
          transparent 33%,
          rgba(0, 0, 0, 0.1) 33%,
          rgba(0, 0, 0, 0.1) 66%,
          transparent 66%
        ),
        -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
        -webkit-linear-gradient(left, #09c, #ff0);
    }
    label {
      background: #e7f4fd;
    }
  }

  .correlation-table {
    font-size: 13px;
    border-collapse: collapse;
    border: 3px solid #77b5df;
    width: 100%;
    th {
      border: 1px solid #e7f4fd;
    }
    th {
      padding-top: 12px;
      padding-bottom: 12px;
      background-color: #77b5df;
      color: $white;
      font-weight: normal;
    }
    td {
      padding: 8px;
      width: 50%;
      height: 15px;
      border: 1px solid #e7f4fd;
    }
    tr {
      background-color: $white;
    }
    tr:hover {
      background-color: $light-blue;
    }
  }

  .correlationScoreDisable {
    max-width: 30px;
    margin-left: 50px;
    background: transparent;
    width: -webkit-fill-available;
    border: none;
    outline: none;
  }

  .correlationScoreEnable {
    max-width: 30px;
    margin-left: 50px;
    width: -webkit-fill-available;
    border: 1px black solid;
  }
  .button-wrapper {
    display: inline-flex;
  }
  .addCorrelationBtn {
    height: 16px;
    width: 16px;
    padding-left: 4px;
    background: $green;
    border-radius: 3px;
    border: none;
    margin-left: 10px;
    color: white;
  }
}
