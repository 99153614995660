  .input-field {
    
    .react-datepicker__time-container .react-datepicker__time{
        width: 100px;
        .react-datepicker__time-box{
          width: 100px;
        }
      }
      .react-datepicker__header--time{
        width: 100px;
      }
      .datepicker-input { 
        padding: 0px 5px;
      }
      .datepicker-wrapper {
        margin-bottom: 5px;
      }
      .Select-arrow-zone{
        padding-top: 5px;
      }
    .no-label {
        input {
          padding-top: 10px;
        }
      }
  }
  