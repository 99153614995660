.main-header-mobile{
    .top{
        margin-left: 48px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        img{
            max-width: 55%;
        }
        img:last-of-type{
            max-width: 30px;
            align-self: center;
            max-height: 30px;
            opacity: 0.6;
        }
    }
    .middle{
        transition: all 1.1s;
        margin-top: -115px;
        transition: all 1s;
        color: transparent;
        border: 1px solid;
        li{
            font-style: italic;
            &:hover{
                cursor: pointer;
                opacity: 0.6;
            }
            a{
                color: transparent;
            }
        }
        &:last-child{
            display: none;
        }
        &.expanded {
            margin-top: 0;
            display: block;
            color: white;
            li:first-child{
                text-align: center;
            }
            li{
                list-style: none;
                font-size: 15px;
                margin:10px 10px;
                padding: 4px;
                a{
                    text-decoration: none;
                    color: white;
                    pointer-events: painted;
                }
            }
            &:last-child{
                display: block;
            }
        }
    }
}