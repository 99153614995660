@import "../Styles/importFiles";

.setHeight50p{
  height: 50px !important;
}
.datepicker-wrapper {
  margin-bottom: 10px;
  width: 100%;
}
.datepicker-input {
  min-width: 0;
  width: 100%;
  border: transparent;
  border-radius: 3px;
  outline: none;
  padding: 6px 10px;
  height: 28px;
  font-size: 13px;
  font-family: $RubikRegular;
  background: $offwhite;

  color: $purple2;
}
.react-datepicker-wrapper {
  display: block;
  position: relative;
  width: 100%;
  &:before {
    content: "";
    background: url("../Images/date-trigger.png") no-repeat 0 center;
    width: 23px;
    height: 18px;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 1;
    margin-top: -9px;
  }
}
.react-datepicker__input-container {
  display: block;
}
.react-datepicker-popper {
  z-index: 10000;
}
.CloseBtn {
  height: 55px;
  width: 55px;
  background: $red;
  border-radius: 3px;
  border: none;
  margin-left: 10px;
  font-size: 0;
  
  &:before {
    @extend %fi;
    font-size: 20px;
    content: $fi-close;
    color: $primaryColor1;
    vertical-align: middle;
    margin: -1px 0px 0 1px;
    box-sizing: border-box;
  }
  
}

.delete-btn{
  display: inline-block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  transition: all .3s;
  box-shadow: none;
  background: #f54949;
  right: 0;
  position: absolute;
  top: 50%;
  margin: -13px 0 9px 10px;
  right: 6px;
  border: 1px solid transparent;
  width: 25px;
  margin-left: 10px;
  border-radius: 50%;
  height: 25px;
  outline: none;
  &:before {
      @extend %Im;
      font-size: 20px;
      content: $fi-close;
      color: #ffffff;
      vertical-align: middle;
      margin: 0px 0px 2px 0px;
      box-sizing: border-box;
    }
    &:hover{
        background: $red;
    }
}

.SaveCloudBtn {
  border: none;
  background: $green;
  width: 55px;
  height: 55px;
  margin-left: 10px;
  @include border-radius(3px);
  padding: 0;
  position: relative;

  &:before {
    @extend %fi;
    font-size: 20px;
    content: $fi-saveCloud;
    line-height: 1.1;
    vertical-align: middle;
    color: $primaryColor1;
  }
}
.AddBtn {
  height: 55px;
  width: 55px;
  background: $green;
  border-radius: 3px;
  border: none;
  margin-left: 10px;
  font-size: 0;
  
  &:before {
    @extend %Im;
    font-size: 20px;
    content: $fi-close;
    color: $primaryColor1;
    vertical-align: middle;
    transform: rotate(45deg);
    margin: -1px 0px 0 1px;
  }
  
}
.App {
    font-family: rubik, Arial, sans-sarif;
    width: 100%;
    background: #3E335E;
    height: 100vh;
    overflow: auto;
}
hr{
  box-sizing: content-box;
    height: 0px;
    color: #77b5df;
    width: 100%;
    border-top: 1px solid #77b5df;
}
.select-input {
  margin-bottom: 10px;
  width: 100%;
  &.inValid {
    border: 1px solid $red;
    border-radius: 3px;
  }

  .Select-control {
    background: $blue-white $imp;
    height: 40px;
    box-shadow: none $imp;
    color: $purple2;
    font-size: 13px;
    padding-top: 10px;
    border: transparent;
    border-radius: 3px;
    .Select-value {
      line-height: 27px;
    }
  }
  .Select-value-label {
    color: $dark-blue $imp;
  }
  .Select-input {
    color: $purple2;
    font-size: 13px;
    height: auto;

    > input {
      min-width: 0;
      padding: 0px 0;
      line-height: 1;
    }
  }
  .Select-placeholder {
    line-height: 30px;
    color: $purple3;
    font-size: 13px;
  }
  .Select-menu-outer {
    border: none;
    &:before {
      content: "";
      box-shadow: rgb(136, 136, 136) 0px 0px 6px;
      position: absolute;
      top: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
    .Select-menu {
      background: $white;
    }
  }
  .Select-noresults {
    padding: 6px 10px;
    font-size: 13px;
  }
  .Select-option {
    padding: 5px 6px;
    font-size: 13px;
  }
}
.btn-login{
    font-family: rubik,Arial,sans-sarif;
    background: #04caa7;
    color: $white;
    font-size: 16px;
    padding: 6px 10px;
    display: inline-block;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.rdw-editor-main{
  background: $white;
  height: 150px;
}
.close-btn{
    align-self: flex-end;
    margin-bottom: 10px;
    width: 40px;
    background: #FFF;
    outline: none;
    padding: 0;
    height: 40px;
    margin-left: 10px;
    border: 1px solid transparent;
    border-radius: 20px;
    margin-right: -15px;
    margin-top: -15px;
    &:before {
      @extend %fi;
      font-size: 20px;
      content: $fi-close;
      color: darken($white, 20);
      vertical-align: middle;
      margin: -1px 0px 0 1px;
      box-sizing: border-box;
    }
    &:hover{
        background: $red;
    }
  }
  .collapseBtn {
    height: 55px;
    width: 55px;
    background: $light-blue;
    border-radius: 3px;
    border: none;
    margin-left: 10px;
    font-size: 0;
    &:before {
      @extend %fa;
      color: $white;
      margin-left: -3px;
      line-height: 1;
      content: $fa-angle-left;
      font-size: 23px;
      @include border-radius(50%);
      box-sizing: border-box;
      display: inline-block;
      @include breakpoint(screen767) {
        @include rotate(-90deg);
      }
    }
    &.expandBtn {
      margin-left: 0;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      padding-bottom: 10px;
      @include breakpoint(screen767) {
        order: 1;
      }
      &:before {
        @include rotate(180deg);
        margin-left: 2px;
        margin-top: 3px;
        @include breakpoint(screen767) {
          @include rotate(90deg);
        }
      }
    }
  }

  //////////////////search
.round-search {
    background: $white;
    padding-left: 10px;
    @include border-radius(3px);
    border: none;
    flex: 1;
    height: 35px;
    line-height: 35px;
    outline: none;
    font-size: 13px;
    border:10px solid $light-blue;
    @include input-placeholder {
      color: $purple3;
    }
  }
  
  .heading {
    font-size: 15px;
    color: $white;
    display: flex;
    flex: 1;
    display: block;
    background: $light-blue;
    border-radius: 3px;
    overflow: hidden;
    p{
      margin-left: 10px;
      margin-top: 20px;
      margin-right: 20px;
      margin-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .expand-heading {
    cursor: pointer;
    background: $light-blue;
    color: $white;
    border-radius: 4px;
    text-align: center;
    font-size: 15px;
    flex: 1;
    margin: 10px 0 0;
    display: flex $imp;
    align-items: center;
    justify-content: center;
    transform: rotateZ(180deg);
    writing-mode: vertical-rl;
    outline: none;

    @include breakpoint(screen767) {
      writing-mode: horizontal-tb;
      transform: none;
      margin: 0 10px 0 0;
      order: -1;
    }
  }
  .header{
    height: 30px;
    background:$purple;
    color: azure;
    display: flex;
    padding: 10px;
    @include breakpoint(screen767){
      flex-flow: column-reverse;
    }
    @include breakpoint(screen991){
      flex-flow: column-reverse;
      height: 100px;  
    }
    .left{
      
        flex: 1;
        padding-left: 5px;
        padding-right: 5px;
        img{
          max-width: 130px;
          margin-left: 10px;
      }
        @include breakpoint(screen767){
          flex-flow: row;
          align-self: center;
          *{
            margin-top: 10px;
          }
        }
    }
    .middle{
        flex: 4;
        li{
            display: inline-block;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 5px;
            a{
                text-decoration: none;
                color: $white;
            }
        }
        @include breakpoint(screen767){
          align-self: center;
            li{
                margin-left: 0px;
                margin-right: 10px;
            }
        }

    }
    .right{
        max-width: 350px;
        .btn-login {margin-left: 10px;}
        @include breakpoint(screen767){
          align-self: center;
          height: 100px $imp;
        }
        
        @include breakpoint(screen767){
            align-self: center;
        }
    }
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3498db;

  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

  #loader:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #e74c3c;

      -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }

  #loader:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #f9c922;

      -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
        animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }

  @-webkit-keyframes spin {
      0%   { 
          -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
          -ms-transform: rotate(0deg);  /* IE 9 */
          transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
      }
      100% {
          -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
          -ms-transform: rotate(360deg);  /* IE 9 */
          transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
      }
  }
  @keyframes spin {
      0%   { 
          -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
          -ms-transform: rotate(0deg);  /* IE 9 */
          transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
      }
      100% {
          -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
          -ms-transform: rotate(360deg);  /* IE 9 */
          transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
      }
  }

  progress:not(value) {
    /* Add your styles here. As part of this walkthrough we will focus only on determinate progress bars. */
  }
  
  /* Styling the determinate progress element */
  
  progress[value] {
    /* Get rid of the default appearance */
    appearance: none;
    
    /* This unfortunately leaves a trail of border behind in Firefox and Opera. We can remove that by setting the border to none. */
    border: none;
    
    /* Add dimensions */
    width: 100%; height: 20px;
    
    /* Although firefox doesn't provide any additional pseudo class to style the progress element container, any style applied here works on the container. */
      background-color: whiteSmoke;
      border-radius: 3px;
      box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;
    
    /* Of all IE, only IE10 supports progress element that too partially. It only allows to change the background-color of the progress value using the 'color' attribute. */
    color: royalblue;
    
    position: relative;
    margin: 0 0 1.5em; 
  }
  
  /*
  Webkit browsers provide two pseudo classes that can be use to style HTML5 progress element.
  -webkit-progress-bar -> To style the progress element container
  -webkit-progress-value -> To style the progress element value.
  */
  
  progress[value]::-webkit-progress-bar {
    background-color: whiteSmoke;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;
  }
  
  progress[value]::-webkit-progress-value {
    position: relative;
    
    background-size: 35px 20px, 100% 100%, 100% 100%;
    border-radius:3px;
    
    /* Let's animate this */
    animation: animate-stripes 5s linear infinite;
  }
  
  @keyframes animate-stripes { 100% { background-position: -100px 0; } }
  
  /* Let's spice up things little bit by using pseudo elements. */
  
  progress[value]::-webkit-progress-value:after {
    /* Only webkit/blink browsers understand pseudo elements on pseudo classes. A rare phenomenon! */
    content: '';
    position: absolute;
    
    width:5px; height:5px;
    top:7px; right:7px;
    
    background-color: white;
    border-radius: 100%;
  }
  
  /* Firefox provides a single pseudo class to style the progress element value and not for container. -moz-progress-bar */
  
  progress[value]::-moz-progress-bar {
    /* Gradient background with Stripes */
    background-image:
    -moz-linear-gradient( 135deg,
                             transparent,
                             transparent 33%,
                             rgba(0,0,0,.1) 33%,
                             rgba(0,0,0,.1) 66%,
                             transparent 66%),
      -moz-linear-gradient( top,
                              rgba(255, 255, 255, .25),
                              rgba(0,0,0,.2)),
       -moz-linear-gradient( left, #09c, #f44);
    
    background-size: 35px 20px, 100% 100%, 100% 100%;
    border-radius:3px;
    
    /* Firefox doesn't support CSS3 keyframe animations on progress element. Hence, we did not include animate-stripes in this code block */
  }
  
  /* Fallback technique styles */
  .progress-bar {
    background-color: whiteSmoke;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;
  
    /* Dimensions should be similar to the parent progress element. */
    width: 100%; height:20px;
  }
  
  .progress-bar span {
    background-color: royalblue;
    border-radius: 3px;
    
    display: block;
    text-indent: -9999px;
  }
  
  p[data-value] { 
    
    position: relative; 
  }
  
  /* The percentage will automatically fall in place as soon as we make the width fluid. Now making widths fluid. */
  
  p[data-value]:after {
    content: attr(data-value) '%';
    position: absolute; right:0;
  }


  //loading mask ends here
/* width */
::-webkit-scrollbar {
  width: 10px;
  margin: 0px 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#77B5DF;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9783cf;
}

